import { IMdCmSearchFields } from '../types/ScmTypes';
import { MdEnableStatusEnum } from '../types/ScmEnums';

//系统标识
export const CLIENT_APP_CODE = 'bcm';

export const DEFAULT_PAGE_SIZE = 15;
export const DEFAULT_FIRST_PAGE_NUM = 1;
export const DEFAULT_REQUEST_TIMEOUT = 30000;
//API请求环境
const baseRequestUrl = {
	DEV: 'https://api.peijian88.cn',
	TEST: 'https://test-api-2.laoniuche.cn',
	PROD: 'https://api-2.yunhaogroup.com',
	STAGE: "https://api.beiben88.com"

};
const baseWSRequestUrl = {
	DEV: `wss://localhost:8089/fms`,
	TEST: `wss://192.168.110.121:8089/fms`,
	PROD: 'wss://82.156.190.89:8089/fms',
    STAGE: 'wss://82.156.190.89:8089/fms',

};
const baseCiRequestUrl = {
	TESTAPI: `https://test.api.yunhaochefu.com`,
	DEV: `https://test.api.yunhaochefu.com`,
	TEST: `https://test.api.yunhaochefu.com`,
	PROD: 'https://api.yunhaochefu.com',
	STAGE: 'https://test.api.yunhaochefu.com',

};
//根据环境变量自动获取请求地址
export const getBaseRequestUrl = () => {
	//自动选择环境
	//....
	const REACT_APP_ENV = process.env.REACT_APP_ENV as 'DEV' | 'TEST' | 'PROD' | "STAGE";
	return baseRequestUrl[REACT_APP_ENV ?? 'DEV'];
};
export const getBaseWSRequestUrl = () => {
	//自动选择环境
	//....
	const REACT_APP_ENV = process.env.REACT_APP_ENV as 'DEV' | 'TEST' | 'PROD' | "STAGE";
	return baseWSRequestUrl[REACT_APP_ENV ?? 'DEV'];
};
//根据环境变量自动获取请求地址
export const getBaseCiRequestUrl = () => {
	const REACT_APP_ENV = process.env.REACT_APP_ENV as "DEV" | "TEST" | "PROD" | "STAGE" | "TESTAPI";
	return baseCiRequestUrl[REACT_APP_ENV ?? "DEV"]
};
//服务器自定义错误码
export const INTERNAL_SERVER_ERROR_CODE = 500;
//token存储KEY值
export const TOKEN_KEY = 'token';
//获取token
export const getToken = () => {
	return sessionStorage.getItem(TOKEN_KEY) ?? 'default-token-str';
};
//存储token
export const setToken = (token: string) => {
	sessionStorage.setItem(TOKEN_KEY, token);
};
//主数据通用字段定义
export const defaultMdCmParam: IMdCmSearchFields = {
	sourceApp: 'bcm',
	sourceAuthKey: '',
	branch: 'main',
	ver: undefined,
	enable: MdEnableStatusEnum.ENABLE,
};
//财务通用字段定义
export const defaultMdCmParamAccout: IMdCmSearchFields = {
	sourceApp: 'bcm',
	sourceAuthKey: '',
	branch: 'bcm',
};
//根据环境变量自动获取图片查看地址
export const getBaseImgLookUrl = (md5: string) => {
    //自动选择环境
    return `${getBaseRequestUrl()}/fsrv/fetch/${md5}?Authorization=${getToken()}`
}

//根据环境变量自动获取图片上传地址
export const getBaseImgUploadUrl = () => {
    //自动选择环境
    return `${getBaseRequestUrl()}/fsrv/upload_file?Authorization=${getToken()}`
}

//根据环境变量自动获取图片上传地址
export const getBaseUploadData = () => {
    return {
        appCode: CLIENT_APP_CODE,
        fileMode: 0,
    }
}
export const branchDs = [
	{ label: '财务分支', value: 'fms' },
	{ label: '主分支', value: 'main' },
	{ label: '供应链分支', value: 'scm' },
	{ label: '云修分支', value: 'vrm' },
	{ label: '客车分支', value: 'bcm' },
];
