import { httpRequest } from '@/utils/HttpRequest';
import { IPage } from '@/types/ScmTypes';
import {
	ISCMPurchaseDrSearch,
	ISCMPurchaseDrDetail,
	ISCMPurchaseDrStatistics,
	ISCMPurchaseDrInsert,
	ISCMPurchaseDrUpdate,
	ISCMPDrGoodsOne,
	ISCMPDrSearch,
	IBCMPOStockGoodsSearch,
	IBCMPODetailsStockDetail,
	ISCMORVRMTransferDetails,
	IVRMQuerySearch,
	IVRMQueryDetails,
	IQueryMaterialList
} from './purchaseDrTypes';
import { IEntityFields } from '@/views/organization/entity/entityType';
import { getBaseCiRequestUrl } from "@/utils/constants";

export interface ISCMPage<T, S> extends IPage<T> {
	statistics: S;
}

export interface IFmsShopUnitDetail {
	bentityList: IEntityFields[];
	address: string;
	bentity: string;
	branch: string;
	branchList: string;
	code: string;
	contact: string;
	createTime: string;
	createUser: string;
	deleteFlag: string;
	enable: string;
	id: string;
	name: string;
	note: string;
	phone: string;
	shop: string;
	shopCode: string;
	shopList: string;
	shopName: string;
	status: string;
	tel: string;
	updateTime: string;
	updateUser: string;
	ver: string;
}


class PurchaseDrService {
	async page(searchVo: ISCMPurchaseDrSearch) {
		const apiUrl = '/sms/transfer/to/listPage';
		return await httpRequest.post<IPage<ISCMPurchaseDrDetail>>(apiUrl, searchVo);
	}
	async pageStatistics(searchVo: ISCMPurchaseDrSearch) {
		const apiUrl = '/sms/transfer/to/TransferInSummary';
		return await httpRequest.post<ISCMPurchaseDrStatistics>(apiUrl, searchVo);
	}
	async one(id: string) {
		const apiUrl = `/sms/transfer/to/findByIdOrCode/${id}`;
		return await httpRequest.get<ISCMPurchaseDrDetail>(apiUrl);
	}

	// inAndOut "1" 是调出 "0"是调入
	async insert(insertVo: ISCMPurchaseDrInsert) {
		const apiUrl = '/sms/transfer/to/insert';
		return await httpRequest.post<any>(apiUrl, insertVo);
	}
	async update(updateVo: ISCMPurchaseDrUpdate) {
		const apiUrl = '/sms/transfer/to/update';
		return await httpRequest.post<any>(apiUrl, updateVo);
	}
	//查询门店结算单位的 下属关联
	async queryShopUnitBe(shopId: string) {
		const apiUrl = `/fms/accountUnit/find/one/${shopId}`;
		return await httpRequest.post<IFmsShopUnitDetail>(apiUrl);
	}

	// 调拨库房确认接口 调入调出   调入：0，调出 1
	async whConfirm(obj: { inAndOut: string; id: string }) {
		const apiUrl = `/sms/transfer/to/whConfirm`;
		return await httpRequest.post<IFmsShopUnitDetail>(apiUrl, obj);
	}

	// 业务系统库房确认接口
	async bcmConfirm(searchVo: ISCMPurchaseDrUpdate) {
		const apiUrl = `/bcm/stock/details/whConfirm`;
		return await httpRequest.post<IFmsShopUnitDetail>(apiUrl, searchVo);
	}

	async export(searchVo: ISCMPurchaseDrSearch) {
		const apiUrl = '/sms/transfer/to/export';
		return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
	}
    //列表明细导出
    async statisticsExport(searchVo: ISCMPurchaseDrSearch) {
        const apiUrl = "/sms/transfer/to/statisticsExport";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
    }
	//调入单明细下载模板
	async downloadTpl() {
		const appPageUrl = '/sms/transfer/to/detail/downloadTpl';
		return await httpRequest.getFile(appPageUrl);
	}
	//导出调入单明细
	async exportDetail(code: string) {
		const appPageUrl = `/sms/transfer/to/exportDetails/${code}`;
		return await httpRequest.getFile(appPageUrl);
	}
	//供应链采购明细查询接口
	async scm2TransferDetails(searchVo: IBCMPOStockGoodsSearch) {
		// const apiUrl = '/scm2/purchase/po/transfer/details';
		const apiUrl = '/scm2/inventory/details/page';
		return await httpRequest.post<IPage<ISCMORVRMTransferDetails>>(apiUrl, searchVo);
	}
	// 库存明细查询
	async bcmTransferDetails(searchVo: IBCMPOStockGoodsSearch) {
		const apiUrl = '/bcm/stock/details/transfer/details';
		return await httpRequest.post<IPage<IBCMPODetailsStockDetail>>(apiUrl, searchVo);
	}

	//云修采购明细查询接口
	async vrm2TransferDetails(searchVo: IBCMPOStockGoodsSearch) {
		const apiUrl = '/vrm2/stock/details/transfer/details';
		return await httpRequest.post<IPage<ISCMORVRMTransferDetails>>(apiUrl, searchVo);
	}
    //ci采购明细查询接口
    async ciTransferDetails(searchVo: IBCMPOStockGoodsSearch, token: string) {
        const apiUrl = "/ci-storage/stock/details/transfer/details";
        return await httpRequest.post<IPage<ISCMORVRMTransferDetails>>(apiUrl, searchVo, { baseURL: getBaseCiRequestUrl(), headers: { token } });
    }
	//ci登录
	async ciLogin() {
		const apiUrl = "/ci-platform/login";
		const params = { "account": "admin", "pwd": "9795e3055b7902d986d2239b86988235", "isRemerber": [] };
		return await httpRequest.post<any>(apiUrl, params, { baseURL: getBaseCiRequestUrl() })
	}
	//ci查询机构列表
	async ciOrgPage(token: string) {
		const apiUrl = "/ci-platform/organ/page";
		const params = { "searchItem": { "orderFlag": 0, "showPlatformOrgan": true }, "pageNum": 1, "pageSize": 10000, "orders": [{ "column": "createTime", "rule": "DESC" }] }
		return await httpRequest.post<any>(apiUrl, params, { baseURL: getBaseCiRequestUrl(), headers: { token } })
	}
	//查询配件最近仓库货位
	async bcmStockQuery(searchVo: IVRMQuerySearch) {
		const apiUrl = '/bcm/stock/details/query';
		return await httpRequest.post<IVRMQueryDetails[]>(apiUrl, searchVo, { timeout: 300000 });
	}
	//查询调入配件是否有在配件档案维护
	async queryMaterial(searchVo:IQueryMaterialList){
		const apiUrl = 'bcm/base/material/queryMaterial';
		return await httpRequest.post<IVRMQueryDetails[]>(apiUrl, searchVo);
	}

	// 查询配件再调入门店的仓库货位
	async selfWh(searchVo: object){
		const apiUrl = 'bcm/base/material/findById/warehouse';
		return await httpRequest.post<IVRMQueryDetails>(apiUrl, searchVo);
	}

	async check (branchIn:string,params:object) {
        const apiUrl = `${branchIn}/base/material/checkMaterialExist`
        return await httpRequest.post<any>(apiUrl, params)
    }
	async findNewShelfCode (params:object) {
        const apiUrl = `/bcm/purchase/batch/getMaterialShelf`
        return await httpRequest.post<any>(apiUrl, params)
    }
    async checkScm (branchIn:string,params:object) {
        const apiUrl = `${branchIn}/purchase/po/checkMaterialExist`
        return await httpRequest.post<any>(apiUrl, params)
    }
}

export const purchaseDrService = new PurchaseDrService();
