import { useEffect, useRef, useState } from 'react'
import { usePurchaseDrDetailsModel } from "./purchaseDrDetailsModel";
import Modal from 'antd/lib/modal/Modal'
import { FormItemTypeEnum, IYhFormBox, IYhFormItemsBox, IYhFormRef } from '@/components/YhFormBox/Types'
import { message } from 'antd'
// import { ISelectOptions } from '@/types/AppTypes'
// import { warehouseService } from '@/views/base/warehouseManage/warehouseService'
import { globalPrompt } from '@/components/message'
import useGlobalModel, { getFindItem, transOptions } from "@/model/globalModel";
import { YhFormBox } from '@/components/YhFormBox'
import { purchaseDrService } from '../../purchaseDrService';

export const ChangeWarehouseModel = ({ mode, visible, setVisible }: { mode: string, visible: boolean, setVisible: (value: boolean) => void }) => {
    const { warehouseList, setLoadingInfo, shopAndOrg, userList, userData, fetchUserList } = useGlobalModel()
    const {editFormVal,editGoodsList,addGoodsList,setEditGoodsList,setAddGoodsList} = usePurchaseDrDetailsModel()
    const formRef = useRef<IYhFormRef>()
    const [data, setData] = useState<any>([])
    const [shelfAllList, setShelfAllList] = useState<any>([])
    const changeshelfCode = async (value:any, options:any) => {
        let dataSource = editGoodsList
        let newList:any=[]
        dataSource.map((item:any)=>{
            if(!item.inWarehouseCode||!item.inWarehouseName||!item.inShelfCode){
                newList.push({
                    shopId:editFormVal.shopId ,
                    materialCode:item.materialCode ,
                    warehouseCode: value
                })
            }
        })
        let { retData } = await purchaseDrService.findNewShelfCode(newList);
		if(retData.length>0){
            let newList:any=[]
            retData.map((list:any)=>newList.push({
                materialCode:list.materialCode,
                inWarehouseCode:value,
                inWarehouseName:value?warehouseList.find((item:any) => item.code === value)?.name:'',
                inShelfCode:list.shelfCode
            }))
            setData(newList)
        }
        else{
            message.warning('仓库无货位记录,代入临时更换“临时货位”')
            setData([{ 
                inWarehouseCode:value,
                inWarehouseName:value?warehouseList.find((item:any) => item.code === value)?.name:'',
                inShelfCode:'临时货位'
            }])
        }

    }
    useEffect(() => {
        setData([])
	}, [visible]);
    const onOk = () => {
        globalPrompt('modal', {
			title: '',
			type: 'warning',
			text: '您是否批量调整仓库货位？',
			okText: '确认',
			onOk:  () => {
                let dataSource = editGoodsList
                let newList:any=[]
                dataSource.map((item:any)=>{
                    if(!item.inWarehouseCode||!item.inWarehouseName||!item.inShelfCode){
                        let newData=data?.find((list:any)=>list?.materialCode==item?.materialCode)
                        if(newData){
                            newList.push({
                                ...item,
                                inWarehouseCode:newData.inWarehouseCode,
                                inWarehouseName:newData.inWarehouseName,
                                inShelfCode:newData.inShelfCode
                            })
                        }else{
                            newList.push({
                                ...item,
                                inWarehouseCode:data[0].inWarehouseCode,
                                inWarehouseName:data[0].inWarehouseName,
                                inShelfCode:'临时货位'
                            })
                        }
                    }else{
                        newList.push(item)
                    }
                })
                setEditGoodsList([...newList])
                setVisible(false)
			},
			cancelText: '取消',
			onCancel: () => {},
		})
    }

    const formItem: IYhFormItemsBox = {
        title: '仓库货位批量调整',
        style: { padding: '20px', marginTop: '20px' },
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: '仓库',
                fieldName: 'inWarehouseCode',
                options: transOptions(warehouseList.filter((item: any) => item.type !== 'warehouse-category.small')),
                span: 24,
                onSelectChange: changeshelfCode,
                render: (text: string) => transOptions(warehouseList).find(item => item.value === text)?.label ?? "请选择仓库",
            },
            // {
            //     type: FormItemTypeEnum.Select,
            //     fieldLabel: '货位',
            //     fieldName: 'inShelfCode',
            //     options: shelfAllList,
            //     disable:true,
            //     span: 24,
            // },
            // {
            //     type: FormItemTypeEnum.Input,
            //     fieldLabel: '货位',
            //     fieldName: 'inShelfCode',
            //     span: 24,
            //     disable:true,
            // },
        ],
    }



    const FromConfig: IYhFormBox<any> = {
        formRef,
        labelAlign: 'right',
        formValues: data,
        boxs: [formItem],
    }

    return (
        <Modal
            visible={visible}
            cancelText="取消"
            okText="确认"
            onCancel={() => {
                setVisible(false)
            }}
            onOk={onOk}
        >
            <YhFormBox {...FromConfig} />
        </Modal>
    )
}
